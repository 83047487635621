* {
  box-sizing: border-box;
  color: #05131d;
}

@keyframes blink {50% { color: transparent }}

$main-color: #0474b6;
$main-color-hover: #005d93;

body {
  float: left;
  width: 100%;
  height: 100%;
  background: #f1f2f3;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #05131d;
}

h2 {
  margin-top: 5rem;
}

h2:first-of-type {
  margin-top: 0.67rem;
}

.header{
  z-index: 20;
  position: fixed;
  width: 100%;
  background-color: #FFF;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 10px 0px rgba(80, 80, 80, 1);
  .inner{
    width: 100%;
    max-width: 990px;
    margin: 0 auto 0 auto;
    padding: 15px;
    .zLogo{
      float: left;
      width: 164px;
      height: 26px;
      background-image: url(../../images/logo-zumtobel.svg);
      -webkit-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 1;
    }
    .search-container{
      height: 26px;
      margin: 7px 0 0 179px;
      input{
        float: right;
        width: 300px;
        padding: 3px;
        border-radius: 2px;
        border-color: #a1abb2;
        backface-visibility: hidden;
        -webkit-appearance: none;
        background-color: #FFF;
        font-family: inherit;
        border-style: solid;
        border-width: 1px;
        color: rgba(0,0,0,.75);
        font-size: .875rem;
        height: 26px;
        transition: all 300ms linear;
        &:focus{
          border-color: #6a7177;
          background: #FFF;
          box-shadow: 0 0 5px rgba(0,0,0,.5);
          outline: 0;
        }
      }
      .submit-button{
        border-radius: 2px;
        margin-left: 10px;
        float: right;
        color: #FFF;
        padding: 4px 15px 4px 15px;
        background-color: $main-color;
        cursor: pointer;
        transition: background-color 300ms ease-out;
        &:hover{
          background-color: $main-color-hover;
        }
      }
    }
  }
}

.wrapper{
  position: absolute;
  width: 100%;
  min-height: 100%;
  padding-bottom: 60px;
  top: 0;
  left: 0;
  //background-color: #f1f2f3;
  .inner{
    padding-top: 80px;
    width: 100%;
    max-width: 990px;
    margin: 0 auto 40px auto;
    small.error {
      display: block;
      width: 100%;
      padding: 0 15px 0;
      margin-bottom: 30px;
    }
    .start-help{
      display: none;
      position: relative;
      width: 100%;
      transition: all 300ms ease-in-out;
      .inner-help{
        position: absolute;
        //width: 100%;
        left: 0;
        top: 0;
        padding: 10px 0 50px 15px;
        a {
          color: #0474b6;
          text-decoration: none;
        }
        table {
          td {
            padding-left: 2rem;
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
    }
    section{
      width: 100%;
      float: left;
      margin: 15px 0 30px 0;
      opacity: 1;
      transition: all 300ms ease-in-out;
      transform: translateY(0) scale(1);
      &.loading {
        opacity: 0;
        transform: translateY(30px) scale(.97);
      }
      h2{
        margin: 10px 0 5px 15px;
        span {
          font-weight: 300;
        }
      }
      h3{
        margin: 5px 0 5px 15px;
        line-height: 1;
      }
      .download-csv {
        display: inline-block;
        width: 100%;
        margin: 5px 0 10px 15px;
        span{
          color: $main-color;
          text-decoration: none;
          cursor: pointer;
          > .loader-dot {
            animation: 1s blink infinite;
            &:nth-child(2) {
              animation-delay: 250ms;
            }
            &:nth-child(3) {
              animation-delay: 500ms;
            }
          }
        }
        .download-done {
          color: #1ea12d;
          &:hover{
            color: #1ea12d;
          }
        }
        .download-loading:hover {
          color: $main-color;
        }
        .download-loading,
        .download-done {
          display: none;
          cursor: default;
        }
        &.loading{
          .download-initial,
          .download-done {
            display: none;
          }
          .download-loading {
            display: block;
          }
        }
        &.done{
          .download-initial,
          .download-loading {
            display: none;
          }
          .download-done {
            display: block;
          }
        }
      }
      .tile-outer{
        float: left;
        width: 50%;
        padding: 15px;
        *{
          -webkit-backface-visibility: hidden;
        }
        .tile{
          float: left;
          width: 100%;
          padding: 15px;
          background-color: #FFF;
          border-radius: 2px;
          cursor: pointer;
          box-shadow: 0 0 3px rgba(0,0,0,.6);
          transform: scale(.99);
          transition: all 300ms ease-in-out;
          &:hover{
            box-shadow: 0 0 6px rgba(0,0,0,.45);
            transform: scale(1);
            .reference-wrapper .clipboard-button {
              opacity: 1;
            }
          }
          &.pressed {
            transition-duration: 150ms;
            box-shadow: 0 0 1px rgba(0,0,0,.8);
            transform: scale(.98);
          }
          small {
            font-size: 12px;
            display: block;
            width: 100%;
            margin-bottom: 5px;
            font-weight: bold;
            span {
              font-weight: 300;
            }
            &:last-of-type {
              padding-bottom: 10px;
              border-bottom: 1px solid #bdbebe;
            }
          }
        }
        .reference-wrapper {
          position: relative;
          float: left;
          width: 100%;
          textarea {
            float: left;
            resize: none;
            border: none;
            padding: 0 20px 0 0;
            width: 100%;
            margin: 10px 20px 0 0;
            //margin-right: 20px;
            font-size: 18px;
            font-weight: bold;
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            overflow: auto;
            &:focus{
              outline: none;
            }
          }
          .clipboard-button {
            opacity: 0;
            transition: opacity 300ms ease-in-out;
            position: absolute;
            right: 0;
            width: 22px;
            height: 22px;
            margin-top: 10px;
            z-index: 2;
            cursor: pointer;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPScxLjEnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycgeG1sbnM6eGxpbms9J2h0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsnIHg9JzBweCcgeT0nMHB4JyB3aWR0aD0nNTEycHgnIGhlaWdodD0nNTEycHgnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyBlbmFibGUtYmFja2dyb3VuZD0nbmV3IDAgMCA1MTIgNTEyJyB4bWw6c3BhY2U9J3ByZXNlcnZlJz4gPHBhdGggZmlsbD0nIzAwNWQ5MycgZD0nTTI4My45NzcsMTAxLjc1N1YyNDFoNDAuMTk4djMwaC00MC4xOTh2MTM5LjI0M2MwLDkuMjcxLDcuNTE2LDE2Ljc4NiwxNi43ODYsMTYuNzg2aDM4LjQxMlY0NjIgaC00OC4zMTZjLTEzLjUzMywwLTI2LjM0LTYuMTI3LTM0LjgzMi0xNi42NjVsLTAuMDY4LTAuMDg1Yy04LjQ5LDEwLjU4OS0yMS4zMjgsMTYuNzUtMzQuOSwxNi43NWgtNDguMjMydi0zNC45NzFoMzguNDEyIGM5LjI3MSwwLDE2Ljc4Ni03LjUxNiwxNi43ODYtMTYuNzg2VjI3MWgtNDAuMTk4di0zMGg0MC4xOThWMTAxLjc1N2MwLTkuMjcxLTcuNTE2LTE2Ljc4Ni0xNi43ODYtMTYuNzg2aC0zOC40MTJWNTBoNDguMzE2IGMxMy41MzMsMCwyNi4zNCw2LjEyNywzNC44MzIsMTYuNjY1bDAuMDY4LDAuMDg1YzguNDktMTAuNTg5LDIxLjMyOC0xNi43NSwzNC45LTE2Ljc1aDQ4LjIzMnYzNC45NzFoLTM4LjQxMiBDMjkxLjQ5Miw4NC45NzEsMjgzLjk3Nyw5Mi40ODYsMjgzLjk3NywxMDEuNzU3eiBNODUsMjA1LjQxN0g1MHYxMDFoMzVWMjA1LjQxN3ogTTg1LDg1aDQyLjgyNXYtMC4wMjlWNTBINTB2MTIxLjQxN2gzNVY4NXogTTEyNy44MjUsNDI3LjAyOVY0MjdIODV2LTg2LjU4M0g1MFY0NjJoNzcuODI1VjQyNy4wMjl6IE0zODQuMTc1LDUwdjM0Ljk3MVY4NUg0Mjd2ODYuNDE3aDM1VjUwSDM4NC4xNzV6IE00MjcsNDI3aC00Mi44MjV2MC4wMjkgVjQ2Mkg0NjJWMzQwLjQxN2gtMzVWNDI3eiBNNDI3LDMwNi40MTdoMzV2LTEwMWgtMzVWMzA2LjQxN3onLz48L3N2Zz4=");
            background-size: contain;
          }
        }
      }
    }
  }
  footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background-color: #f1f2f3;
    .inner{
      width: 100%;
      max-width: 990px;
      margin: 0 auto 0 auto;
      padding-top: 1rem;
      img {
        float: left;
        margin: 0 15px 15px 15px;
      }
      .links-wrapper{
        float: right;
        margin: 5px 15px 15px 0;
        a{
          color: $main-color;
          text-decoration: none;
          margin-left: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 40em) {
  .wrapper .inner section .tile-outer {
    width: 100%;
  }
}
.starting .wrapper .inner .start-help {
  display: block;
  opacity: 1;
}
.animate .wrapper .inner .start-help {
  display: block;
  opacity: 0;
}

